import React, { createContext, useReducer, useContext } from "react"

//Define Context
const GlobalStateContext = createContext<any>({
  cursorType: false,
  cursorColor: '#F5F5F5',
  cursorText: ''
});
const GlobalDispatchContext = createContext((args: any) => {});

//Reducer
const globalReducer = (state, action) => {
  switch (action.type) {
    case "CURSOR_TYPE": {
      return {
        ...state,
        cursorType: action.cursorType,
      }
    }
    case "CURSOR_COLOR": {
      return {
        ...state,
        cursorColor: action.cursorColor,
      }
    }
    case "CURSOR_TEXT": {
      return {
        ...state,
        cursorText: action.cursorText,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

//Provider

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, {
    cursorType: false,
    cursorColor: "#F5F5F5",
    cursorText: '',
    cursorStyles: ["pointer", "hovered", "locked", "white"],
  })

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

//custom hooks for when we want to use our global state
export const useGlobalStateContext = () => useContext(GlobalStateContext)

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
